import Image from "next/image";
import Link from "next/link";
import Swal from 'sweetalert2'
import abi from '../abi/abi.json'
import { useAccount, useWalletClient} from 'wagmi'
import { getContract } from 'wagmi/actions'
import { parseEther } from 'viem'



const hero = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data: walletClient } = useWalletClient(); 
  
  
 
 

  async function mint (){
   
    try{
      if(!address){
        Swal.fire(
          'Wallet desconectada!',
          'Debe conectarse con su wallet primero!',
          'error'
        )
        return;
      }
        await walletClient.writeContract({
          address: '0x6105825Ac7b08EC2dDbD704198E5dB99F9EE66C8',
          abi: abi,
          functionName: 'safeMint',
          value: parseEther('0.0061'),
          gas: 30000n 
        }).then((tx)=>{
        publicClient.waitForTransaction(tx.hash)}).catch(()=>{
          let timerInterval
          Swal.fire({
            title: 'El usuario a rechazado la transaccion!',
            html: 'Volviendo a la pagina de perfil en <b></b> milliseconds.',
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer')
            }
          })          
      })     
    } catch (err) {
      let timerInterval
      Swal.fire({
        title: 'Error en  la transaccion!',
        html: 'Volviendo a la pagina de perfil en <b></b> milliseconds.',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      })
    }
  }
  return (
    <section className="relative pb-10 pt-20 md:pt-32 h-1527">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <Image
          width={1519}
          height={760}
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full object-cover"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <Image
          width={1519}
          height={760}
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full object-cover"
        />
      </picture>

      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">
              NFTs,  para el metaverso.
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              Escobar ha resucitado esta vez en el metaverso, y está aquí para cambiar las reglas del
              juego y construir nuevos proyectos de juegos NFT.
            </p>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              La white List esta habilitada para que participes de este grandioso proyecto.
              Adquiere el token de acceso temprano y queda registrado en la white list por solo 0.006 eth
            </p>
            <div className="flex space-x-4">
            
            <button onClick={mint} className="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                           
                  Mint
            </button> 
             
              
            </div>
          </div>

          {/* <!-- Hero image --> */}
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pl-8 md:text-right">
              <Image
                width={560}
                height={560}
                src="/images/hero/museo.png"
                alt="hero"
                className="hero-img mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
              />
              <Image
                width={740}
                height={602}
                src="/images/hero/3D_elements.png"
                alt="floating image"
                className="animate-fly absolute top-0 md:-right-[10%] "
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero;

