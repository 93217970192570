import HeadLine from "../headLine";
import { newseLatterData } from "../../data/newseLatterData";
import Image from "next/image";
import Collections from "../../components/collection-content/"

const NewseLatter = ({ bgWhite = true }) => {
  return (
    <section className="dark:bg-jacarta-800 relative py-24">
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
            width={1559}
            height={761}
          />
        </picture>
      )}

      <div className="container">
        <HeadLine
          text="Soy Escobar te invita a ser parte de su familia y a tomar las riendas del negocio."
          classes="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white"
        />
        
         <p className="text-jacarta-700 mx-auto mt-20 max-w-2xl text-center text-lg dark:text-white">
            Registrate en nuestra lista de acceso temprano y consigue increíbles beneficios:
          </p>

        <div className="grid grid-cols-1 gap-12 md:grid-cols-2 mt-12 lg:grid-cols-4">
          {newseLatterData.map((item) => {
            const { id, icon, title, text } = item;
            return (
              <div className="text-center newseLatter-item" key={id}>
                <div
                  className={`mb-6 inline-flex rounded-full p-3`}
                  style={{ backgroundColor: icon.parentBg }}
                >
                  <div
                    className={`inline-flex h-12 w-12 items-center justify-center rounded-full`}
                    style={{ backgroundColor: icon.childBg }}
                  >
                    <svg className="icon icon-wallet h-5 w-5 fill-white">
                      <use xlinkHref={`/icons.svg#${icon.svg}`}></use>
                    </svg>
                  </div>
                </div>
                <h3 className="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
                  {id}. {title}
                </h3>
                <p className="dark:text-jacarta-300">{text}</p>
              </div>
            );
          })}
        </div>

        <br></br>
        <br></br>
        <br></br>

       <hr></hr>
       <br></br>
       <hr></hr>


        <div>
          <center>
          <br></br>
          <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-center lg:text-6xl xl:text-7xl">
              TEAM
          </h1>
          </center>
        </div>

        <hr></hr>
        <br></br>
       <hr></hr>

        <Collections/>
        <div className="mx-auto mt-24 max-w-full text-center">
          <HeadLine
            text="Los dueños de Soy Escobar NFT consiguen."
            classes="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white"
          />
          <ul className=" max-w-full space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li className="flex items-center">
                  <svg className="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                  </svg>
                  Acceso y rol de participación en la comunidad Soy Escobar NFT en Discord.
              </li>
              <li className="flex items-center">
                  <svg className="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                  </svg>
                  Posibilidad de coleccionar y redimir escultura de Pablo Escobar elaborada por el Maestro Julio Jaramillo.
              </li>
              <li className="flex items-center">
                  <svg className="w-3.5 h-3.5 mr-2 text-gray-500 dark:text-gray-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                  </svg>
                  Acceso Exclusivo a Avatares listos para el metaverso.

              </li>
              <li className="flex items-center">
                  <svg className="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                  </svg>
                  Acceso Exclusivo a mercaderia Soy Escobar NFT.
              </li>
              <li className="flex items-center">
                  <svg className="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                  </svg>
                  Invitaciones a eventos virtuales e IRL y fiestas.
              </li>
              <li className="flex items-center">
                  <svg className="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                  </svg>
                  Acceso anticipado a todos los futuros proyectos de Soy Escobar NFT en preventa, lista banca y lanzamientos desde el aire.

              </li>
              <li className="flex items-center">
                  <svg className="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                  </svg>
                  Actualizaciones de Avatar y accesorios exclusivos para el metaverso.
              </li>
          </ul>
        </div>
        <div>
          <center>
          <br></br>
          <br></br>
          <br></br>
          <hr></hr>
        <br></br>
       <hr></hr>
       <br></br>
          <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-center lg:text-6xl xl:text-7xl">
              METAMUSEO
              SOY ESCOBAR
          </h1>
          </center>
        </div>

        <hr></hr>
        <br></br>
       <hr></hr>


      </div>
    </section>
  );
};

export default NewseLatter;
