const newseLatterData = [
	{
		id: '1',
		icon: { parentBg: '#beaaf7', childBg: 'rgb(131 88 255) ', svg: '' },

		title: ['Cabeza voxel'],
		text: "Cabeza voxelart Oficial de Soy Escobar NFT, para tu avatar en.",
	},
	{
		id: '2',
		icon: { parentBg: '#c4f2e3', childBg: 'rgb(16 185 129)', svg: '' },

		title: ['Obtener', 'Digito', 'Cedula'],
		text: 'Aumenta las probabilidades de obtener un dígito de la Cédula de Pablo Escobar, si coleccionas los 7 dígitos podrás redimir una escultura oficial de Pablo Escobar NFT de mano del Maestro Julio Jaramillo.',
	},
	{
		id: '3',
		icon: { parentBg: '#cddffb', childBg: 'rgb(66 138 248)', svg: '' },
		title: ['Precio preferencial'],
		text: 'Precio preferencial de acceso temprano solo 0.06 eth frente a 0.1 eth precio	lanzamiento general.',
	},
	{
		id: '4',
		icon: { parentBg: '#ffd0d0', childBg: 'rgb(239 68 68)', svg: '' },
		title: ['Eventos'],
		text: 'Acceso a eventos, juegos, reuniones y dinámicas en el metamuseo Soy Escobar',
	},
];

export { newseLatterData };
