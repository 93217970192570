import Image from "next/image";
import Link from "next/link";

const GridItems = () => {
  const items = [
    {
      id: 1,
      imageSrc: "/images/collections/leo.gif",
      title: "Leonardo Torres",
      price: "Director General",
      verified: true,
    },
    {
      id: 2,
      imageSrc: "/images/collections/nico.gif",
      title: "Nicolas Escobar",
      price: "Director Museo Pablo Escobar",
      verified: true,
    },
    {
      id: 3,
      imageSrc: "/images/collections/julio.gif",
      title: "Julio Londoño",
      price: "Director Centro Artístico y Cultural Socrates",
      verified: true,
    },
    {
      id: 4,
      imageSrc: "/images/collections/cami.gif",
      title: "Camilo Marquez",
      price: "Artista Soy Escobar NFT",
      verified: true,
    },
    {
      id: 5,
      imageSrc: "/images/collections/juan_r.gif",
      title: "Juan R. Yepes ",
      price: "Desarrollador Solidity",
      verified: true,
    },
    {
      id: 6,
      imageSrc: "/images/collections/chami.gif",
      title: "Juan Camilo Restrepo",
      price: "Lider Redes Sociales",
      verified: true,
    },
    {
      id: 7,
      imageSrc: "/images/collections/pipe.gif",
      title: "Felipe Isaza",
      price: "Asistente General",
      verified: true,
    },
    {
      id: 8,
      imageSrc: "/images/collections/edson.gif",
      title: "Edson Bedoya",
      price: "Lider Comunidad",
      verified: true,
    },
    
  ];

  

  return (
    <>
      {items.map((item) => (
        <article key={item.id}>
          <div className="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
            <Link href="#">
              <Image
                width={270}
                height={270}
                src={item.imageSrc}
                alt={item.alt}
                className="w-full h-full object-cover rounded-[0.625rem]"
                loading="lazy"
              />
            </Link>

            <Link
              href="/collection/avatar_1"
              className="mt-5 mb-2 flex items-center font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent"
            >
              {item.title}
              {item.verified && (
                <div
                  className="flex h-[1.125rem] w-[1.125rem] ml-1 mb-px items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                  title="Verified Collection"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-[.875rem] w-[.875rem] fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                  </svg>
                </div>
              )}
            </Link>

            <div className="font-medium text-2xs text-jacarta-700 dark:text-white mb-2">
              {item.price}
            </div>
            
          </div>
        </article>
      ))}
    </>
  );
};

export default GridItems;

